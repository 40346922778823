import React from 'react'
import { 
    AiOutlineWhatsApp, 
    AiOutlinePhone, 
    AiOutlineMail 
} from 'react-icons/ai'

import { HiOutlineLocationMarker } from 'react-icons/hi'

import { 
    ContatoBox, 
    ContatoContainer, 
    ContatoItem, 
    ContatoTexto, 
    ContatoTitulo, 
    Contatos, 
    Icone, 
    Mapa, 
    MapaBox 
} from './ContatoElements'

const Contato = () => {
  return (
    <ContatoContainer id='contatos'>
        <MapaBox>
            <ContatoTitulo>Contato</ContatoTitulo>
            <Mapa src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3980.9714934859358!2d-38.
            60919729051141!3d-3.816235043607047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7c74d
            9c1177f269%3A0xbefe60f72f45faea!2sR.%20Umarizeiras%2C%20977%20-%20Canindezinho%2C%20Fortaleza
            %20-%20CE%2C%2060765-505!5e0!3m2!1spt-BR!2sbr!4v1685893711264!5m2!1spt-BR!2sbr" 
            allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
        </MapaBox>
        <ContatoBox>
            <Contatos>
                <ContatoItem>
                    <Icone><AiOutlineWhatsApp /></Icone>
                    <ContatoTexto>(85) 99177-0817</ContatoTexto>
                </ContatoItem>
                <ContatoItem>
                    <Icone><AiOutlineMail /></Icone>
                    <ContatoTexto>Vendas@luimit.com</ContatoTexto>
                </ContatoItem>
                <ContatoItem>
                    <Icone><HiOutlineLocationMarker /></Icone>
                    <ContatoTexto>Rua Umarizeiras 977 Parque Presidente, Fotaleza - CE </ContatoTexto>
                </ContatoItem>
            </Contatos>
        </ContatoBox>
    </ContatoContainer>
  )
}

export default Contato
import React, { useEffect } from 'react'
import { 
    Nav, 
    NavButtom, 
    NavbarContainer, 
    NavbarLink, 
    NavbarLogo, 
    Titulo 
} from '../Filter/FilterNavbarElements'
import { 
    PisImg,
    SimuladorBox, 
    SimuladorContainer,
    SimulatorButton,
    SimulatorDescription,
    SubBox, 
    ViewBox, 
    VinilBox, 
    VinilDescription, 
    VinilImg 
} from './NavSimuladorElements';

import vinil1 from '../../images/estampa1.jpg'
import vinil2 from '../../images/estampa2.jpg'
import vinil3 from '../../images/estampa3.jpg'
import vinil4 from '../../images/estampa4.jpg'
import vinil5 from '../../images/estampa5.jpg'
import vinil6 from '../../images/estampa6.jpg'
import vinil7 from '../../images/estampa7.jpg'
import vinil8 from '../../images/estampa8.jpg'
import vinil9 from '../../images/estampa9.jpg'
import vinil10 from '../../images/estampa10.jpg'
import vinil11 from '../../images/estampa11.jpg'
import vinil12 from '../../images/estampa12.jpg'
import vinil13 from '../../images/estampa13.jpg'
import vinil14 from '../../images/estampa14.jpg'
import vinil15 from '../../images/estampa15.jpg'
import vinil16 from '../../images/estampa16.png'

import pishow1 from '../../images/vinilshow1.jpg'
import pishow2 from '../../images/vinilshow2.jpg'
import pishow3 from '../../images/vinilshow3.jpg'
import pishow4 from '../../images/vinilshow4.jpg'
import pishow5 from '../../images/vinilshow5.jpg'
import pishow6 from '../../images/vinilshow6.jpg'
import pishow7 from '../../images/vinilshow7.jpg'
import pishow8 from '../../images/vinilshow8.jpg'
import pishow9 from '../../images/vinilshow9.jpg'
import pishow10 from '../../images/vinilshow10.jpg'
import pishow11 from '../../images/vinilshow11.jpg'
import pishow12 from '../../images/vinilshow12.jpg'
import pishow13 from '../../images/vinilshow13.jpg'
import pishow14 from '../../images/vinilshow14.jpg'
import pishow15 from '../../images/vinilshow15.jpg'
import pishow16 from '../../images/vinilshow16.jpg'
import { useState } from 'react';


const NavSimulador = () => {

    var pishows = [pishow1, pishow2, pishow3, pishow4, pishow5, pishow6, pishow7, pishow8, pishow9, pishow10, pishow11, pishow12, pishow13, pishow14, pishow15, pishow16]

    const [cont, setCont] = useState(0);

    const gotoHome = (event) => {

        window.location.href = '#/';
    }

    const gotoFilter = (event) => {

        window.location.href = `#/orcamento/1/${cont}`;
    }

    useEffect(() => {

        window.scrollTo(0,0);
        
    }, []);

  return (
    <>
        <Nav>
            <NavbarContainer>
                <NavbarLink onClick={gotoHome} spy={true} offset={-100} smooth={true} duration={500} to="topo"><NavbarLogo /></NavbarLink>
                <NavButtom onClick={gotoHome}>Home</NavButtom>
            </NavbarContainer>
        </Nav>
        <Titulo id='topo'>Simulador</Titulo>
        <SimuladorContainer>
            <SimuladorBox>
                <SubBox>
                    <VinilBox test='0' verif={cont} onClick={() => setCont(0)} spy={true} offset={-200} smooth={true} duration={500} to="show">
                        <VinilImg img={vinil1}></VinilImg>
                        <VinilDescription>Vidro</VinilDescription>
                    </VinilBox>
                    <VinilBox onClick={() => setCont(1)} spy={true} offset={-200} smooth={true} duration={500} to="show">
                        <VinilImg img={vinil2}></VinilImg>
                        <VinilDescription>Master</VinilDescription>
                    </VinilBox>
                    <VinilBox onClick={() => setCont(2)} spy={true} offset={-200} smooth={true} duration={500} to="show">
                        <VinilImg img={vinil3}></VinilImg>
                        <VinilDescription>Liso</VinilDescription>
                    </VinilBox>
                    <VinilBox onClick={() => setCont(3)} spy={true} offset={-200} smooth={true} duration={500} to="show">
                        <VinilImg img={vinil4}></VinilImg>
                        <VinilDescription>Reflexo</VinilDescription>
                    </VinilBox>
                </SubBox>
                <SubBox>
                    <VinilBox onClick={() => setCont(4)} spy={true} offset={-200} smooth={true} duration={500} to="show">
                        <VinilImg img={vinil5}></VinilImg>
                        <VinilDescription>Natural Stone</VinilDescription>
                    </VinilBox>
                    <VinilBox onClick={() => setCont(5)} spy={true} offset={-200} smooth={true} duration={500} to="show">
                        <VinilImg img={vinil6}></VinilImg>
                        <VinilDescription>Cobalto</VinilDescription>
                    </VinilBox>
                    <VinilBox onClick={() => setCont(6)} spy={true} offset={-200} smooth={true} duration={500} to="show">
                        <VinilImg img={vinil7}></VinilImg>
                        <VinilDescription>Stone Blue</VinilDescription>
                    </VinilBox>
                    <VinilBox onClick={() => setCont(7)} spy={true} offset={-200} smooth={true} duration={500} to="show">
                        <VinilImg img={vinil8}></VinilImg>
                        <VinilDescription>Angra</VinilDescription>
                    </VinilBox>
                </SubBox>
                <SubBox>
                    <VinilBox onClick={() => setCont(8)} spy={true} offset={-200} smooth={true} duration={500} to="show">
                        <VinilImg img={vinil9}></VinilImg>
                        <VinilDescription>Ilhabela Blue</VinilDescription>
                    </VinilBox>
                    <VinilBox onClick={() => setCont(9)} spy={true} offset={-200} smooth={true} duration={500} to="show">
                        <VinilImg img={vinil10}></VinilImg>
                        <VinilDescription>Ilhabela Green</VinilDescription>
                    </VinilBox>
                    <VinilBox onClick={() => setCont(10)} spy={true} offset={-200} smooth={true} duration={500} to="show">
                        <VinilImg img={vinil11}></VinilImg>
                        <VinilDescription>Maragogi</VinilDescription>
                    </VinilBox>
                    <VinilBox onClick={() => setCont(11)} spy={true} offset={-200} smooth={true} duration={500} to="show">
                        <VinilImg img={vinil12}></VinilImg>
                        <VinilDescription>Maresias</VinilDescription>
                    </VinilBox>
                </SubBox>
                <SubBox>
                    <VinilBox onClick={() => setCont(12)} spy={true} offset={-200} smooth={true} duration={500} to="show">
                        <VinilImg img={vinil13}></VinilImg>
                        <VinilDescription>Parati</VinilDescription>
                    </VinilBox>
                    <VinilBox onClick={() => setCont(13)} spy={true} offset={-200} smooth={true} duration={500} to="show">
                        <VinilImg img={vinil14}></VinilImg>
                        <VinilDescription>Trancoso</VinilDescription>
                    </VinilBox>
                    <VinilBox onClick={() => setCont(14)} spy={true} offset={-200} smooth={true} duration={500} to="show">
                        <VinilImg img={vinil15}></VinilImg>
                        <VinilDescription>Floripa</VinilDescription>
                    </VinilBox>
                    <VinilBox onClick={() => setCont(15)} spy={true} offset={-200} smooth={true} duration={500} to="show">
                        <VinilImg img={vinil16}></VinilImg>
                        <VinilDescription>Jericoacoara</VinilDescription>
                    </VinilBox>
                </SubBox>
            </SimuladorBox>
            <ViewBox>
                <PisImg id='show' img={pishows[cont]}></PisImg>
                <SimulatorDescription>*Selecione a textura desejada e verifique sua aplicação simulada</SimulatorDescription>
                <SimulatorButton onClick={gotoFilter}>Selecionar Vinil</SimulatorButton>
            </ViewBox>
        </SimuladorContainer>
    </>
  )
}

export default NavSimulador
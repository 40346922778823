import React from 'react'
import { 
    ContentExtra,
    ExtraBox, 
    ExtraContainer,
    ImageContainer,
    ImageExtra,
    TextContainer
} from './ExtraElements'

const ExtraSection = ({ img1, img2, img3, text1, text2, text3 }) => {
  return (
    <ExtraBox>
        <ExtraContainer $mid={false}>
            <TextContainer>
                <ContentExtra $mid={false}>
                    {text1}
                </ContentExtra>
            </TextContainer>
            <ImageContainer>
                <ImageExtra $img={img1} $mid={false}/>
            </ImageContainer>
        </ExtraContainer>
        <ExtraContainer $mid={true}>
            <TextContainer>
                <ContentExtra $mid={true}>
                    {text2}
                </ContentExtra>
            </TextContainer>
            <ImageContainer>
                <ImageExtra $img={img2} $mid={true}/>
            </ImageContainer>
        </ExtraContainer>
        <ExtraContainer $mid={false}>
            <TextContainer>
                <ContentExtra $mid={false}>
                    {text3}
                </ContentExtra>
            </TextContainer>
            <ImageContainer>
                <ImageExtra $img={img3} $mid={false}/>
            </ImageContainer>
        </ExtraContainer>
    </ExtraBox>
  )
}

export default ExtraSection
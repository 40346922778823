import React, { useEffect } from 'react'
import Aos from 'aos';
import "aos/dist/aos.css"
import {
    SobreBox,
    SobreContainer,
    SobreTitle,
    SobreDesc,
    SobreIcons,
    SobreIconPis,
    SobreIconRef,
    SobreContBox,
    ImgSobre,
    SobreImg 
} from './SobreElements'

const Sobre = () => {

    useEffect(() => {

        Aos.init({ duration: 1000 });

    }, []);
  return (
    
    <SobreBox id='quem-somos'>
        <SobreImg>
            <ImgSobre />
        </SobreImg>
        <SobreContainer>
            <SobreContBox>
                <SobreTitle>A LUIMIT</SobreTitle>
                <SobreDesc>
                A Luimit Piscinas é uma empresa especializada na construção e reformas
                 de piscinas de alvenaria, hidromassagens, lagos artificiais e áreas gourmet
                  em geral. Com uma experiência sólida de mais de 15 anos no mercado da
                   construção civil, a empresa foi fundada por Luiz Carlos e Mitiel,
                    dois empreendedores apaixonados pelo ramo das piscinas.
                </SobreDesc>
            </SobreContBox>
            <SobreIcons data-aos='fade-down'>
                <SobreIconPis />
                <SobreIconRef />
            </SobreIcons>
        </SobreContainer>
    </SobreBox>
  )
}

export default Sobre
import React from 'react'
import { 
  BoxDescription,
  Descriprion,
  HomeBox, 
  HomeContainer, 
  HomeImage, 
  HomeTitle 
} from './PortfolioElements'

const HomePort = () => {
  return (
    
    <HomeBox>
        <HomeContainer>
          <HomeTitle>Nossas Piscinas</HomeTitle>
          <HomeImage>
            <BoxDescription>
              <Descriprion>
                As boas práticas nas etapas de construção de uma piscina garantem a melhor qualidade e confiância para o lazer do cliente. Boa orgnaização, boa dinâmica e a utilização de métodos eficazes para a grantia de qualidade de nossas piscinas.
              </Descriprion>
            </BoxDescription>
          </HomeImage>
        </HomeContainer>
    </HomeBox>
  )
}

export default HomePort
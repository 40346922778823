import React from 'react'
import {
    PiscinasContainer,
    PiscinasLogo,
    PiscinasBox,
    CardPiscinas,
    CardContent,
    CardTitle,
    CardDesc,
    CardButton,
    LogoPis,
    CardSoft,
    CardPremium,
    CardMaster,
    PiscinaObs
} from './PiscinasElements'

const Piscinas = () => {

    const gotoFilter = (event) => {

        window.location.href = '#/orcamento/0/0';
    }

  return (
    <PiscinasContainer id='pis'>
        <PiscinasLogo>
            <LogoPis />
        </PiscinasLogo>
        <PiscinasBox>
            <CardPiscinas>
                <CardSoft />
                <CardContent>
                    <CardTitle>Soft</CardTitle>
                    <CardDesc>R$29.000,00 - R$53.200,00</CardDesc>
                    <CardButton id='Soft' onClick={gotoFilter}>Orçamento</CardButton>
                </CardContent>
            </CardPiscinas>
            <CardPiscinas>
                <CardPremium />
                <CardContent>
                    <CardTitle>Premium</CardTitle>
                    <CardDesc>R$32.200,00 - R$55.900,00</CardDesc>
                    <CardButton id='Premium' onClick={gotoFilter}>Orçamento</CardButton>
                </CardContent>
            </CardPiscinas>
            <CardPiscinas>
                <CardMaster />
                <CardContent>
                    <CardTitle>Master</CardTitle>
                    <CardDesc>R$51,500,00 - R$79.500,00</CardDesc>
                    <CardButton id='Master' onClick={gotoFilter}>Orçamento</CardButton>
                </CardContent>
            </CardPiscinas>
            <PiscinaObs>Obs: Valores podendo variar de acordo com a cotação do mercado</PiscinaObs>
        </PiscinasBox>
    </PiscinasContainer>
  )
}

export default Piscinas
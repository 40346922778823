import styled from "styled-components";
import home from '../../../images/home_port.jpg'

export const HomeBox = styled.header`

    width: 100%;

    @media(max-width: 600px) {

        padding-bottom: 300px;
    }
`;

export const HomeContainer = styled.div`

    width: 100%;
    max-width: 1200px;
    margin: auto;
    text-align: center;
`;

export const HomeTitle = styled.h1`

    width: 100%;
    text-align: center;
    padding: 50px 0px;
    margin-bottom: 50px;
    font-size: 80px;
    color: #2b3180;

    @media(max-width: 600px) {

        font-size: 70px;
    }
`;

export const HomeImage = styled.div`

    display: block;
    width: 90%;
    height: 500px;
    margin: auto;
    border-radius: 0px 80px 0px 80px;
    background-image: url(${home});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0% 70%;
    position: relative;
    box-shadow: 0px 0px 10px gray;
`;

export const BoxDescription = styled.div`

    width: 90%;
    text-align: center;
    padding: 30px;
    position: absolute;
    bottom: -15%;
    right: -10%;
    border-radius: 10px;
    background-color: rgba(43, 49, 128, 0.7);

    @media(max-width: 600px) {

        bottom: -50%;
        right: 5%;
    }
`;

export const Descriprion = styled.p`

    width: 100%;
    text-align: center;
    font-size: 28px;
    color: white;

    @media(max-width: 600px) {

        font-size: 24px;
    }
`;
import React from 'react'
import { 
    Nav, 
    NavbarContainer, 
    NavbarLink, 
    NavbarLogo, 
    NavButtom 
} from '../components/Filter/FilterNavbarElements'
import Footer from '../components/Footer'
import HomePort from '../components/Portfolio/Home'
import Section from '../components/Portfolio/Section'
import ExtraSection from '../components/Portfolio/ExtraSection'

const Portfolio = () => {

    function gotoHome() {

        window.location.href = '/'
    }

  return (

    <>

    <Nav>
        <NavbarContainer>
            <NavbarLink onClick={gotoHome} ><NavbarLogo /></NavbarLink>
            <NavButtom onClick={gotoHome}>Home</NavButtom>
        </NavbarContainer>
    </Nav>

    <HomePort />

    <Section 
        main={1} 
        side1={1} 
        side2={2} 
        title='Estrutura e Alvenaria' 
        content='A utilização de blocos de concreto garantem uma maior resistência estrutural, além de uma maior durabilidade. Gatantem também um bom isolamento térmico para água da pisicina.'
        sidetext='Com o radiê e a estrutura formada, tem-se a aplicação dos aditivos no reboco e no chapisco. Garantindo assim, uma impermeabilização eficiente '
        />

    <ExtraSection 
        img1={1} 
        img2={2} 
        img3={3}
        text1='Dos aditivos aplicados de forma correta, fazem com que a estrutura ganhe qualidade no que se trata de resistencia, isolamento e impermeabilização'
        text2='Com materiais a base de polímeros é feita mais uma camada de revestimento e ipermeabilização, visando segurança e qualidade'
        text3='Por fim com a alvenaria, temos a última camada de revestimento, assim como proteção, isolamento e, é claro, a estética da piscina ' 
        />

    <Section 
        main={2} 
        side1={3} 
        side2={4} 
        title='Hidráulica' 
        content='Crucial para garantir um bom funcionamento, é toda a linha hidráulica que faz a piscina funcionar e ser eficiente. Proveniente de dispositivos e lógicas.'
        sidetext='DIspomos de sistemas e dispositivos de filtro, retorno, segurançae e de bomba. Fazendo a piscina conter a qualidade e eficiência que desejamos.'
        />

    <Section 
    main={3} 
    side1={5} 
    side2={6} 
    title='Quadros de Comando' 
    content='São essenciais em sistemas elétricos e fundamentais quando se trata de gerenciar sistemas. Com a montagem dos nossos próprios painéis, tornamos o trabalho bastante completo'
    sidetext='Para a nossas piscinas podemos desenvolver sistemas dedicados para automação de bombas, iluminação, alarmes e etc. '
    />

    <Footer />
    
    </>

  )
}

export default Portfolio
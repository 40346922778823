import React from 'react'
import { FaBars } from 'react-icons/fa'
import {
    MobileIcon,
    Nav,
    NavbarContainer,
    NavbarItem,
    NavbarLink,
    NavbarList,
    NavbarLogo
} from './NavbarElements'

const Navbar = ({toggle}) => {

  return (
    <>
        <Nav>
            <NavbarContainer>
                <NavbarLink spy={true} offset={-100} smooth={true} duration={500} to="home"> <NavbarLogo /></NavbarLink>
                <MobileIcon onClick={toggle}>
                    <FaBars />
                </MobileIcon>
                <NavbarList>
                    <NavbarItem>
                        <NavbarLink spy={true} offset={-100} smooth={true} duration={500} to="home" href=''> Home </NavbarLink>
                    </NavbarItem>
                    <NavbarItem>
                        <NavbarLink spy={true} offset={-100} smooth={true} duration={500} to="quem-somos" href=''> Quem Somos </NavbarLink>
                    </NavbarItem>
                    <NavbarItem>
                        <NavbarLink spy={true} offset={-100} smooth={true} duration={500} to="vinil" href=''> Piscinas </NavbarLink>
                    </NavbarItem>
                    <NavbarItem>
                        <NavbarLink spy={true} offset={-100} smooth={true} duration={500} to="ref" href=''> Reformas </NavbarLink>
                    </NavbarItem>
                    <NavbarItem>
                        <NavbarLink spy={true} offset={-100} smooth={true} duration={500} to="contatos" href=''> Contatos </NavbarLink>
                    </NavbarItem>
                    <NavbarItem>
                        <NavbarLink onClick={() => (window.location.href='#/portfolio')} href='#/portfolio'> Portfolio </NavbarLink>
                    </NavbarItem>
                </NavbarList>
            </NavbarContainer>
        </Nav>
    </>
  )
}

export default Navbar
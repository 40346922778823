import styled from 'styled-components'
import capa from '../../images/capa.webp'
import {Link as LinkS} from 'react-scroll'

export const CapaBox = styled.header`

    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${capa});
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: 70vh;
`;

export const CapaContent = styled.div`

    text-align: center;
    color: white;
    padding: 20px;
    width: 60%;
    animation: moveToLeft 1s ease-in-out;

    @keyframes moveToLeft {

        0% {
            transform: translateX(1300px);
        }

        100% {
            transform: translateX(0px)
        }
    }

    @media(max-width: 600px) {

        width: 90%;
    }
`;

export const CapaTitle = styled.h1`

    font-size: 50px;
    margin-bottom: 30px;

    @media(max-width: 600px) {

        font-size: 40px;
    }
`;

export const CapaDesc = styled.h1`

    font-size: 20px;
    margin: 10px 0px;
    margin-bottom: 50px;
`;

export const CapaButton = styled(LinkS)`

    display: inline;
    font-size: 22px;
    background-color: transparent;
    border: 1px solid white;
    font-weight: bold;
    color: white;
    border-radius: 20px;
    padding: 10px 20px;

    &:hover {

        background-color: white;
        color: #36a9e1;
        cursor: pointer;
    }

    @media(max-width: 600px) {

        margin-top: 50px;
        font-size: 21px;
        padding: 20px 20px;
    }
`;
import React from 'react'

import { 
    AiOutlineWhatsApp, 
    AiOutlineMail,
    AiOutlineInstagram,
    AiOutlineFacebook 
} from 'react-icons/ai'

import { MdOutlineWaterDrop } from 'react-icons/md'

import {
    FooterBox,
    FooterColumn,
    FooterTitle,
    FooterList,
    ContatoItem,
    Icone,
    ContatoTexto,
    BoxMidia,
    Icones,
    FooterCopy
} from './FooterElements'

const Footer = () => {
  return (
    <FooterBox>
        <FooterColumn>
            <FooterTitle>Contato</FooterTitle>
            <FooterList>
                <ContatoItem>
                    <Icone><AiOutlineWhatsApp /></Icone>
                    <ContatoTexto>(85) 98773-6035</ContatoTexto>
                </ContatoItem>
                <ContatoItem>
                    <Icone><AiOutlineMail /></Icone>
                    <ContatoTexto>Vendas@luimit.com</ContatoTexto>
                </ContatoItem>
            </FooterList>
        </FooterColumn>
        <FooterColumn>
            <FooterTitle>Serviços</FooterTitle>
            <FooterList>
                <ContatoItem>
                    <Icone><MdOutlineWaterDrop /></Icone>
                    <ContatoTexto>Reformas</ContatoTexto>
                </ContatoItem>
                <ContatoItem>
                    <Icone><MdOutlineWaterDrop /></Icone>
                    <ContatoTexto>Constrções</ContatoTexto>
                </ContatoItem>
                <ContatoItem>
                    <Icone><MdOutlineWaterDrop /></Icone>
                    <ContatoTexto>Manutenções</ContatoTexto>
                </ContatoItem>
            </FooterList>
        </FooterColumn>
        <FooterColumn>
            <FooterTitle>Redes Sociais</FooterTitle>
            <FooterList>
                <BoxMidia>
                    <Icones>
                        <Icone tamanho='big' aria-label='Instagram' href='https://instagram.com/luimitpiscinas?igshid=MzRlODBiNWFlZA==' target='_blank'><AiOutlineInstagram /></Icone>
                        <Icone tamanho='big'aria-label='Facebook' href='https://www.facebook.com/luimitpiscinas?mibextid=ZbWKwL' target='_blank'><AiOutlineFacebook /></Icone>
                    </Icones>
                </BoxMidia>
            </FooterList>
        </FooterColumn>
        <FooterCopy>© TODOS OS DIREITOS RESERVADOS - IMAGENS MERAMENTE ILUSTRATIVAS</FooterCopy>
    </FooterBox>  
    )
}

export default Footer
import styled from "styled-components"
import back from '../../images/textura_piscina.jpg'

export const FooterBox = styled.footer`

    padding: 50px 50px 30px 50px;
    background-image: linear-gradient(to top, rgba(43,49,128, 0.9), rgba(43,49,128, 0.9)), url(${back});
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 100px;
`;

export const FooterColumn = styled.div`

    width: 350px;
    text-align: start;

    @media(max-width: 600px) {

        justify-content: center;
    }
`;

export const FooterTitle = styled.h3`

    font-size: 24px;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    text-shadow: 0px 0px 3px black;
    margin-bottom: 20px;
`;

export const FooterList = styled.div`

    color: white;
    text-shadow: 0px 0px 3px black;
    height: 150px;
`;

export const BoxMidia = styled.div`

    display: flex;
    align-items: baseline;
    position: relative;
`;

export const Icones = styled.div`

    display: flex;
    width: 150px;
    position: absolute;
    bottom: -150px;
    right: 0;
    justify-content: end;
`;

export const FooterItem = styled.div`

    padding: 10px 10px 10px 0px;
    font-size: 18px;
`;

export const ContatoItem = styled.div`

    display: flex;
    padding: 5px;
`;

export const Icone = styled.a`

    color: white;
    font-size: ${({tamanho}) => (tamanho === 'big' ? '40px' : '30px')};
    text-decoration: none;
    margin: 0px 5px;
    cursor: pointer;
`;

export const ContatoTexto = styled.p`

    color: white;
    font-size: 18px;
    padding-top: 3px;
    padding-left: 10px;
`;

export const FooterCopy = styled.div`

    width: 100%;
    display: flex;
    justify-content: center;
    color: white;
    font-weight: 600;
    font-size: 14px;
    padding: 50px 10px 0px 10px;
`;
import styled from "styled-components";
import logo from '../../images/logo_slide.png'

export const ObgBox = styled.div`

    width: 800px;
    padding: 20px;
    margin: auto;
    margin-top: 100px;
    text-align: center;

    @media( max-width: 600px) {

        width: 100%;
    }
`;

export const ObgMensage = styled.h1`

    color: #2b3180;
    font-size: 50px;
    font-weight: 700;
`;

export const ObgImg = styled.div`

    width: 300px;
    height: 300px;
    background-image: url(${logo});
    background-size: cover;
    margin: auto;
`;
import styled from "styled-components";
import logoRef from '../../images/logo_ref_sobre.png'
import logoPis from '../../images/logo_site.png'
import img from '../../images/img_sobre.jpg'

export const SobreBox = styled.section`

    display: flex;
    align-items: center;
    margin: 100px 0px;
    padding: 0px 2%;

    @media(max-width: 600px) {

        flex-wrap: wrap;
        margin: 0px;
    }
`;

export const SobreContainer = styled.div`

    width: 60%;
    text-align: start;
    padding-right: 50px;

    @media(max-width: 600px) {

        width: 100%;
        text-align: center;
        padding-right: 0px;
    }
`;

export const SobreImg = styled.div`

    text-align: start;
`;

export const ImgSobre = styled.div`

    width: 500px;
    height: 500px;
    background-image: url(${img});
    background-position: center;
    border-radius: 100%;
    background-color: white;
    margin-left: -50px;

    @media(max-width: 600px) {

        position: fixed;
        right: -50px;
        height: 0px;
    }
`;

export const SobreContBox = styled.div`

    display: block;
    width: 80%;
    margin: auto;
    text-align: center;
    box-sizing: border-box;
    margin-top: 100px;
`;

export const SobreTitle = styled.h2`

    font-size: 55px;
    color: #2b3180;
    padding: 20px;
`;

export const SobreDesc = styled.div`

    font-size: 20px;
    padding: 10px;
    display: block;
    white-space: wrap;

    @media(max-width: 600px) {
        
        padding: 10px 0px;
    }
`;

export const SobreIcons = styled.div`

    width: 100%;
    height: 100px;
    display: flex;
    margin: auto;
    margin-top: 20px;
`;

export const SobreIconPis = styled.div`

    width: 50%;
    height: 100%;
    background-image: url(${logoPis});
    background-position: center;
    background-size: cover;
`;

export const SobreIconRef = styled.div`

    width: 50%;
    height: 100%;
    background-image: url(${logoRef});
    background-position: center;
    background-size: cover;
`;
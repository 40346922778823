import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import 'swiper/css/bundle'
import './Carrosel.css'
import {
    CarroselContainer,
    ComentBox,
    IconeService,
    Logo,
    LogoDesc,
    Service,
    ServiceDesc,
    SideDoll,
    SideImg,
    Slide, 
    SlideContent,
    SlideImg,
    SlideSide
} from './CarroselElements'

const Carrosel = () => {

  return (
    <>
        <CarroselContainer>
            <Swiper 
            modules={[Autoplay]} 
            autoplay={{delay: 3000}} 
            loop={true}
            >
                <SwiperSlide className='transparente'>
                    <Slide>
                        <SlideContent>  
                            <Logo />
                            <LogoDesc>
                                A equipe de profissionais da Luimit é altamente capacitada e conta
                                com engenheiros, arquitetos, designers e técnicos especializados em piscinas
                            </LogoDesc>
                        </SlideContent>
                        <SlideSide>
                            <ComentBox Comentario='1'/>
                            <ComentBox Comentario='2'/>
                            <ComentBox Comentario='3'/>
                        </SlideSide>
                    </Slide>
                </SwiperSlide>
                <SwiperSlide>
                    <Slide>
                        <SlideContent>
                            <Service>
                                <IconeService />
                                <ServiceDesc>Empresa especializada na construção e reforma de piscinas</ServiceDesc>
                            </Service>
                            <Service>
                                <IconeService />
                                <ServiceDesc>Desde a concepção do projeto até a construção e finalização</ServiceDesc>
                            </Service>
                            <Service>
                                <IconeService />
                                <ServiceDesc>Referência no mercado de construção de piscinas e áreas de lazer em geral</ServiceDesc>
                            </Service>
                            <Service>
                                <IconeService />
                                <ServiceDesc>Qualidade de atendimento e transparência nos negócios</ServiceDesc>
                            </Service>
                        </SlideContent>
                        <SlideSide>
                            <SideDoll />
                        </SlideSide>
                    </Slide>
                </SwiperSlide>
                <SwiperSlide>
                    <Slide>
                        <SlideContent>
                            <SlideImg />
                        </SlideContent>
                        <SlideSide>
                            <SideImg Foto='1'/>
                            <SideImg Foto='2'/>
                        </SlideSide>
                    </Slide>
                </SwiperSlide>
            </Swiper>
        </CarroselContainer>
    </>
  )
}

export default Carrosel
import styled from "styled-components";

export const ContatoContainer = styled.section`

    display: flex;
    flex-wrap: wrap;
    margin-top: 100px;
    position: relative;
    padding-left: 50px;
    justify-content: start;
    box-sizing: border-box;

    @media(max-width: 600px) {

        padding: 0px;
        justify-content: end;
    }
`;

export const ContatoBox = styled.div`

    height: 500px;
    width: 450px;
    border-radius: 100% 0px 0px 100%;
    background-color: #2b3180;
    padding-left: 20px;
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    @media(max-width: 600px) {

        position: relative;
        margin-top: 100px;
        justify-content: start;
        width: 400px;
    }
`;

export const Contatos = styled.div`

    padding: 10px;
    width: 350px;
    box-sizing: border-box;

    @media (max-width: 600px) {

        width: 300px;
        padding-left: 20px;
    }
`;

export const ContatoItem = styled.div`

    display: flex;
    padding: 10px;
    margin: 10px 0px 10px 20px;
    box-sizing: border-box;
`;

export const Icone = styled.i`

    color: white;
    font-size: 40px;

    @media (max-width: 600px) {

        font-size: 30px;
    }
`;

export const ContatoTexto = styled.p`

    color: white;
    font-size: 22px;
    padding-top: 5px;
    padding-left: 20px;

    @media (max-width: 600px) {

        font-size: 20px;
    }
`;

export const MapaBox = styled.div`

    width: 700px;
    height: 100%;
    text-align: center;
    box-sizing: border-box;

    @media(max-width: 600px) {

        width: 100%
    }
`;

export const ContatoTitulo = styled.h2`

    font-size: 60px;
    color: #2b3180;
    padding: 10px;
    margin-bottom: 50px;
`;

export const Mapa = styled.iframe`

    width: 90%;
    height: 350px;
    border-radius: 10px;
`;
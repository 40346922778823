import styled from "styled-components";
import back from '../../images/back_reforma.png'
import logo from '../../images/logo_ref.png'
import foto1 from '../../images/ref1.webp'
import foto2 from '../../images/ref7.webp'
import foto3 from '../../images/ref3.webp'
import foto4 from '../../images/ref4.webp'
import foto5 from '../../images/ref5.webp'
import foto6 from '../../images/ref6.webp'
import foto7 from '../../images/ref7.webp'
import foto8 from '../../images/ref8.webp'
import foto11 from '../../images/pisos.jpg'
import foto12 from '../../images/alvenarias.jpg'
import foto13 from '../../images/eletrica.jpg'
import foto14 from '../../images/gerenciamento.jpg'
import foto15 from '../../images/gesso.jpg'
import foto16 from '../../images/pintura.jpg'
import foto17 from '../../images/projetos.jpg'
import icone from '../../images/icone.png'



export const ReformasContainer = styled.section`

    margin-top: 100px;
`;

export const ReformasLogo = styled.div`

    height: 100px;
    width: 300px;
    background-image: linear-gradient(to top, rgba(239,126,82, 0.9), rgba(239,126,82, 0.9)), url(${back});
    border-radius: 0px 10px 0px 0px;
`;

export const LogoRef = styled.div`

    width: 100%;
    height: 100%;
    background-image: url(${logo});
`;

export const ReformasBox = styled.div`

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    padding: 60px 30px 60px 10px;
    background-image: linear-gradient(to top, rgba(239,126,82, 0.9), rgba(239,126,82, 0.9)), url(${back});

    @media(max-width: 600px) {

        padding: 10px 10px 50px 10px;
    }
`;

export const CarouselRef = styled.div`

    width: 70%;
    height: 400px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    box-sizing: border-box;

    @media(max-width: 600px) {

        width: 100%;
    }
`;

export const ReformasFotos = styled.div`

    width: 100%;
    height: 350px;
    display: flex;
    box-sizing: border-box;
    overflow: auto;
    align-items: center;
    transition: 0.5s;
    position: relative;

    &::-webkit-scrollbar {

        display: none;
    }
`;

export const Buttons = styled.div`

    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 20px;
`;

export const ButtonArrow = styled.button`

    font-size: 50px;
    color: white;
    width: 50px;
    height: 50px;
    border: none;
    margin: 0px 10px;
    cursor: pointer;
    background-color: transparent;

    &:hover {

        font-size: 53px;
    }

    @media (max-width: 600px) {

        margin: 0px 30px;
    }
`;

export const Foto = styled.div`

    width: ${({Foto, position}) => (Foto === position ? '400px' : '200px')};
    height: ${({Foto, position}) => (Foto === position ? '330px' : '150px')};
    opacity: ${({Foto, position}) => (Foto === position ? '1' : '0.6')};
    margin: 0px 10px;
    box-shadow: 0px 0px 10px black;
    background-image: url(${({Foto}) => (Foto === '1' ? foto11 : Foto === '2' ? foto12 : Foto === '3' ? foto13 : Foto === '4' ? foto14 : Foto === '5' ? foto15 : Foto === '6' ? foto16 : foto17)});
    background-size: cover;
    background-position: ${({Foto}) => (Foto === '3' ? 'top' : 'center')};
    transition: 0.1s;
    flex: none;
    position: relative;

    @media(max-width: 600px) {

        width: 90%;
        height: 300px;
        margin-top: 30px;
        display: ${({Foto, position}) => (Foto === position ? 'block' : 'none')};
        margin: auto;
        z-index: ${({Foto, position}) => (Foto === position ? '999' : '1')};
    }
`;

export const ReformasTopicos = styled.div`

    width: 20%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-left: 40px;
    font-weight: 900;

    @media(max-width: 600px) {

        width: 100%;
        justify-content: center;
        margin-top: 50px;
        margin-left: 0;
    }
`;

export const LinhaTopico = styled.div`

    width: 100%;
    display: flex;
    justify-content: start;
    box-sizing: border-box;
    margin-top: 20px;

    @media(max-width: 600px) {

        margin-left: 30px;
    }
`;

export const IconeTopico = styled.div`

    box-sizing: border-box;
    background-image: url(${icone});
    background-size: cover;
    width: 50px;
    height: 50px;
`;

export const Topico = styled.p`

    box-sizing: border-box;
    text-shadow: 0px 0px 3px black;
    color: white;
    font-size: 26px;
    list-style: none;
    padding-left: 10px;
    padding-top: 10px;
`;

export const RefButton = styled.button`

    border: 2px solid white;    
    background-color: white;
    color: rgba(239,126,82, 1);
    font-size: 24px;
    font-weight: bold;
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;

    &:hover {

        border: 2px solid #ff4834; 
        background-color: #ff4834;
        color: white;
    }

    @media(max-width: 600px) {

        margin-top: 20px;
        width: 90%;
    }
`;

export const RefGaleria = styled.div`

    width: 95%;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 100px;
`;

export const ImgGaleria = styled.div`

    width: 30%;
    height: 400px;
    background-image: url(${({Foto}) => (Foto === '1' ? foto1 : Foto === '2' ? foto2 : Foto === '3' ? foto3 : Foto === '4' ? foto4 : Foto === '5' ? foto5 : Foto === '6' ? foto6 : Foto === '6' ? foto7 : foto8)});
    background-size: cover;
    margin: 5px;
    cursor: pointer;
    transition: 0.5s;

    &:hover {

        width: 33%;
        box-shadow: 0px 0px 20px black;
        
    }

    @media (max-width: 600px) {

        width: 90%;

        &:hover {

            width: 93%;
            box-shadow: 0px 0px 20px black;

        }
    }
`;

export const SubFoto = styled.div`

    text-shadow: 0px 0px 5px black;
    font-size: 26px;
    font-weight: bold;
    position: absolute;
    text-align: center;
    width: 100%;
    color: white;
    bottom: 0;
    padding: 20px;
    -webkit-text-stroke-width: 0.1px;
    -webkit-text-stroke-color: white;
`;

export const RefNome = styled.input`

    border: 3px solid rgba(239,126,82, 1);    
    background-color: white;
    color: gray;
    width: 285px;
    font-size: 24px;
    font-weight: bold;
    padding: 10px;
    margin-top: 40px;

    &:focus {

        outline: none;
    }

    @media(max-width: 600px) {

        width: 355px;
        margin-top: 50px;
    }
`;
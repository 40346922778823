import React from 'react'
import NavFilter from '../components/Filter';


const Filter = () => {

  return (
    
    <>
        <NavFilter />
    </>
  )
}

export default Filter
import styled from 'styled-components'
import logo from '../../images/logo.png'
import {Link as LinkS} from 'react-scroll'


export const Nav = styled.nav`
    width: 100%;
    position: sticky;
    top: 0;
    background: #fff;
    box-shadow: 0px 1px 10px black;
    z-index: 995;
    @media screen and (max-width:768px) {
        position: static;
    }
`;

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 10px 20px;
    @media screen and (max-width: 768px) {
        padding: 10px 0;
    }
`;

export const NavbarLogo = styled.div`
    
    justify-self: flex-start;
    background-image: url(${logo});
    background-size: cover;
    cursor: pointer;
    margin-left: 10px;
    width: 200px;
    height: 80px;

    @media screen and (max-width: 600px) {
        margin-left: 0;
    }
`;

export const MobileIcon = styled.div`
    display: none;
    @media screen and (max-width: 768px) {
        display: block;
        font-size: 1.8rem;
        cursor: pointer;
        color: #2b3180;
        justify-self: end;
        margin-right: 20px;
    }
`;

export const NavbarList = styled.ul`
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: 30px;
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const NavbarItem = styled.li`
    margin: 0 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const NavbarLink = styled(LinkS)`
    text-decoration: none;
    color: #2b3180;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    &:hover {
        color: #ff4834
    }
`;
import React from 'react'
import { 
    CapaBox,
    CapaContent,
    CapaTitle,
    CapaDesc,
    CapaButton
} from './CapaElements'

const Capa = () => {

  return (
    <CapaBox id='home'>
        <CapaContent>
            <CapaTitle>Encontre a sua piscina ideal!</CapaTitle>
            <CapaDesc>As melhores piscinas com a melhor qualidade você encontra na LUIMIT! Desde a construção até a reforma</CapaDesc>
        </CapaContent>
    </CapaBox>
  )
}

export default Capa
import styled from "styled-components";
import back from '../../images/textura_piscina.jpg'
import logo from '../../images/logo_pis.png'
import soft from '../../images/soft3.jpg'
import premium from '../../images/premium.jpg'
import master from '../../images/master.jpg'

export const PiscinasContainer = styled.section`

    margin-top: 150px;
`;

export const PiscinasLogo = styled.div`

    height: 100px;
    width: 300px;
    background-image: linear-gradient(to top, rgba(54,169,225, 0.9), rgba(54,169,225, 0.9)), url(${back});
    border-radius: 0px 10px 0px 0px;
`;

export const LogoPis = styled.div`

    width: 100%;
    height: 100%;
    background-image: url(${logo});
`;

export const PiscinasBox = styled.div`

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 50px 10px;
    background-image: linear-gradient(to top, rgba(54,169,225, 0.9), rgba(54,169,225, 0.9)), url(${back});

    @media(max-width: 600px) {

        padding: 10px 10px 50px 10px;
    }
`;

export const PiscinaObs = styled.div`

    color: white;
    padding: 40px 10px 0px 10px;
    font-size: 16px;
`;

export const CardPiscinas = styled.div`

    border-radius: 10px;
    height: 400px;
    width: 340px;
    background-color: white;
    margin-top: 50px;
    box-shadow: 0px 0px 10px black;

    @media(max-width: 600px) {

        width: 330px;
    }
`;

export const CardSoft = styled.div`

    background-image: url(${soft});
    background-size: cover;
    background-position: bottom;
    height: 50%;
    border-radius: 10px 10px 0px 0px;
`;

export const CardPremium = styled.div`

    background-image: url(${premium});
    background-size: cover;
    background-position: bottom;
    height: 50%;
    border-radius: 10px 10px 0px 0px;
`;

export const CardMaster = styled.div`

    background-image: url(${master});
    background-size: cover;
    background-position: bottom;
    height: 50%;
    border-radius: 10px 10px 0px 0px;
`;

export const CardContent = styled.div`

    padding: 10px;
    text-align: center;
`;

export const CardTitle = styled.h3`

    font-size: 26px;
    color: #2b3180;
    padding: 10px 10px 0px 10px;
`;

export const CardDesc = styled.p`

    padding: 10px;
    font-size: 20px;
    color: #36a9e1;
    font-weight: 700;
    padding: 18px 0px;
`;

export const CardButton = styled.button`

    color: white;
    background-color: #2b3180;
    border: 1px solid #2b3180;
    margin: 10px;
    font-size: 24px;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;

    &:hover {

        background-color: #36a9e1;
        border: 1px solid #36a9e1;
    }
`;
import React from 'react'
import { 
    ObgBox, 
    ObgImg, 
    ObgMensage 
} from '../components/Obrigado'
import { useParams } from 'react-router-dom'

const Obrigado = () => {

  let link;
  const params = useParams();

  if (params.desc === 'desc') {

    if (params.vinil === 'vinil') {

      link = `https://wa.me/5585991770817/?text=Ol%c3%a1%2c+meu+nome+%c3%a9+${params.nome}%2c+busco+uma+piscina+na+linha+${params.pacote}+com+as+dimens%c3%b5es+${params.dimensoes}.+Vinil%3a+n%c3%a3o`;

    } else {

      link = `https://wa.me/5585991770817/?text=Ol%c3%a1%2c+meu+nome+%c3%a9+${params.nome}%2c+busco+uma+piscina+na+linha+${params.pacote}+com+as+dimens%c3%b5es+${params.dimensoes}.+Vinil%3a+${params.vinil}`;
    }
  
  } else {

    if (params.vinil === 'vinil') {

      link = `https://wa.me/5585991770817/?text=Ol%c3%a1%2c+meu+nome+%c3%a9+${params.nome}%2c+busco+uma+piscina+na+linha+${params.pacote}+com+as+dimens%c3%b5es+${params.dimensoes}.+Vinil%3a+n%c3%a3o.%0d%0a%0d%0a${params.desc}`;
    
    } else {

      link = `https://wa.me/5585991770817/?text=Ol%c3%a1%2c+meu+nome+%c3%a9+${params.nome}%2c+busco+uma+piscina+na+linha+${params.pacote}+com+as+dimens%c3%b5es+${params.dimensoes}.+Vinil%3a+${params.vinil}.%0d%0a%0d%0a${params.desc}`;
    } 
  }

  setTimeout(function() {

    window.open(link, '_blank');
    window.location.href = '#/filter';

  }, 3000);

  return (

    <ObgBox>
        <ObgImg/>
        <ObgMensage>Agradecemos seu orçamento!</ObgMensage>
    </ObgBox>
  )
}

export default Obrigado
import styled from "styled-components";
import poli from '../../../images/poli.jpg'
import reboco from '../../../images/reboco.webp'
import alvenaria from '../../../images/alvenaria.jpg'

export const ExtraBox = styled.section`

    width: 100%;
`;

export const ExtraContainer = styled.div`

    width: 100%;
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: ${({ $mid }) => ($mid ? 'row-reverse' : 'unset')};
    justify-content: center;
    margin-bottom: 100px;
`;

export const TextContainer = styled.div`

    width: 50%;
    display: flex;
    align-items: center;

    @media(max-width: 600px) {

        width: 100%;
        margin-bottom: 70px;
    }
`;

export const ContentExtra = styled.p`

    width: 80%;
    margin: auto;
    padding: 30px 20px;
    text-align: center;
    font-size: 28px;
    color: ${({ $mid }) => ($mid ? 'white' : 'black')};
    border-radius: ${({ $mid }) => ($mid ? '10px' : 'unset')};;
    background-color: ${({ $mid }) => ($mid ? 'rgba(43, 49, 128, 0.7)' : 'unset')};

    @media(max-width: 600px) {

        width: 90%;
    }
`;

export const ImageContainer = styled.div`

    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media(max-width: 600px) {

        width: 100%;
    }
`;

export const ImageExtra = styled.div`

    width: 80%;
    height: 300px;
    background-image: ${({ $img }) => ($img === 1 ? `url(${reboco})` : $img === 2 ? `url(${poli})` : $img === 3 ? `url(${alvenaria})` : 'unset')};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: ${({ $mid }) => ($mid ? '0px 30px 0px 30px' : '30px')};
    box-shadow: 0px 0px 10px gray;
`;
import React from 'react'
import { 
    ContentSide,
    ImageSection,
    ImageSide,
    SectionBox, 
    SectionContainer,
    SectionContent,
    SectionImage,
    SectionText,
    SectionTitle,
    SideImage,
    SideText
} from './SectionElements'

const Section = ( { side1, side2, main, title, content, sidetext }) => {
  return (
    <SectionBox>
        <SectionContainer>
            <SectionText>
                <SectionTitle>{title}</SectionTitle>
                <SectionContent>
                    {content}
                </SectionContent>
            </SectionText>
            <SectionImage>
                <ImageSection $main={main}/>
            </SectionImage>
        </SectionContainer>
        <SectionContainer>
            <SectionImage>
                <SideImage>
                    <ImageSide $side={side1}/>
                    <ImageSide $side={side2} />
                </SideImage>
            </SectionImage>
            <SectionText>
                <SideText>
                    <ContentSide>
                        {sidetext}
                    </ContentSide>
                </SideText>
            </SectionText>
        </SectionContainer>
    </SectionBox>
  )
}

export default Section
import styled from 'styled-components'
import logo from '../../images/logo.png'
import premium1 from '../../images/Premium1.jpeg'
import premium2 from '../../images/Premium2.jpeg'
import premium3 from '../../images/Premium3.jpeg'
import soft1 from '../../images/soft1.jpeg'
import soft2  from '../../images/soft2.jpeg'
import soft3  from '../../images/soft3.jpg'
import master1 from '../../images/master1.jpeg'
import master2  from '../../images/master2.jpeg'
import master3  from '../../images/master3.jpeg'
import premiumV1 from '../../images/premium_vinil1.jpg'
import premiumV2 from '../../images/premium_vinil2.jpg'
import premiumV3 from '../../images/premium_vinil3.jpg'
import softV1 from '../../images/soft_vinil1.jpg'
import softV2  from '../../images/soft_vinil2.jpg'
import softV3  from '../../images/soft_vinil3.jpg'
import masterV1 from '../../images/master_vinil1.jpg'
import masterV2  from '../../images/master_vinil2.jpg'
import masterV3  from '../../images/master_vinil3.jpg'

import { Link as LinkS } from 'react-scroll'

export const Nav = styled.nav`
    width: 100%;
    position: sticky;
    top: 0;
    background: #fff;
    box-shadow: 0px 1px 10px black;
    z-index: 995;
    @media screen and (max-width:768px) {
        position: static;
    }
`;

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 10px 20px;
    @media screen and (max-width: 768px) {
        padding: 10px 0;
    }
`;

export const NavbarLogo = styled.div`
    
    justify-self: flex-start;
    background-image: url(${logo});
    background-size: cover;
    cursor: pointer;
    margin-left: 10px;
    width: 200px;
    height: 80px;

    @media screen and (max-width: 600px) {
        margin-left: 0;
    }
`;

export const NavButtom = styled.button`

    justify-self: end;
    align-self: end;
    margin: 10px;
    padding: 10px 30px;
    font-size: 24px;
    font-weight: bold;
    background-color: #2b3180;
    color: white;
    cursor: pointer;
    border-radius: 10px;
    border: none;

    &:hover {

        background-color: #36a9e1;
    }
`;

export const Titulo = styled.h2`

    font-size: 60px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    color: #2b3180;
    margin: 50px 0px;
`;

export const OrcBox = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
`;

export const LinhaBox = styled.div`

    width: 70%;
    display: ${({show}) => (show === 1 ? 'none' : 'block')};

    @media(max-width: 600px) {

        width: 95%;
    }
`;

export const Selecao = styled.div`

    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

export const LinhaBotao = styled.button`

    width: 15%;
    height: 50px;
    padding: 10px 30px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: white;
    background-color: ${({Active}) => (Active === '0' ? '#2b3180' : '#36a9e1')};
    border: none;
    border-right: 2px solid white;
    cursor: pointer;

    &:hover {
        background-color: #36a9e1;
    }

    @media(max-width: 600px) {

        width: 90%;
        border-bottom: 2px solid white;
    }
`;

export const CaractBox = styled.div`

    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;

    @media(max-width: 600px) {

        justify-content: start;
    }
`;

export const TituloTable = styled.div`

    display: flex;
    width: 100%;
    justify-content: end;
    height: 50px;
`;

export const ColumnTitle = styled.div`

    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2b3180;
    border-left: 1px solid #2b3180;
    font-weight: bold;
    font-size: 24px;
    color: white;

    @media(max-width: 600px) {

        width: 20%;
        font-size: 14px;
    }
`;

export const LinhaTable = styled.div`

    display: flex;
    width: 100%;
    justify-content: center;
`;

export const ColumnTable = styled.div`

    width: 25%;
    border: 1px solid #2b3180;
    padding: 15px 10px;
    color: #36a9e1;
    font-size: 20px;
    font-weight: 600;
    box-sizing: border-box;
    border-left: 5px solid #2b3180;

    @media(max-width: 600px) {

        width: 40%;
        font-size: 16px;
        padding: 15px 5px;
    }
`;

export const ColumnIcone = styled.div`

    width: 25%;
    border: 1px solid #2b3180;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ativo}) => (ativo === '0' ? 'white' : '#9fdbfcad')};
    color: ${({Set}) => (Set === 'not' ? 'red' : 'green')};
    font-size: ${({Set}) => (Set === 'not' ? '40px' : '30px')};

    @media(max-width: 600px) {

        width: 20%;
    }
`;

export const Galeria = styled.div`

    width: 100%;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-top: 20px;

    @media (max-width: 600px) {

        width: 100%;
        padding: 0px;
        justify-content: center;
    }
`;

export const Foto = styled.div`

    width: 33%;
    height: 400px;
    border: 1px solid white;
    background-image: url(${({isProp}) => (isProp === '0' ? soft1 : isProp === '1' ? soft2 : isProp === '2' ? soft3 : isProp === '3' ? premium1 : isProp === '4' ? premium2 : isProp === '5' ? premium3 : isProp === '6' ? master1 : isProp === '7' ? master2 : isProp === '8' ? master3: isProp === '9' ? softV1 : isProp === '10' ? softV2 : isProp === '11' ? softV3 : isProp === '12' ? premiumV1 : isProp === '13' ? premiumV2 : isProp === '14' ? premiumV3 : isProp === '15' ? masterV1 : isProp === '16' ? masterV2 : isProp === '17' ? masterV3 : '')});
    background-size: cover;
    background-position: center;

    @media (max-width: 600px) {

        width: 90%;
        margin-bottom: 5px;
    }
`;

export const DadosBox = styled.div`

    width: 60%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media(max-width: 600px) {

        width: 100%
    }
`;

export const ButtonSubmit = styled.button`

    background-color: #2b3180;
    color: white;
    border: none;
    border-radius: 10px;
    margin: auto;
    padding: 20px;
    font-size: 24px;
    width: 100%;
    height: 65px;
    text-align: center;
    cursor: pointer;

    &:hover {

        background-color: #36a9e1;
    }

    @media(max-width: 600px) {

        width: 90%;
        height: unset;
        margin: auto;
    }
`;

export const InputFilter = styled.input`

    border-radius: 10px;
    font-size: 20px;
    padding: 10px;
    margin: 10px 0px;
    width: 300px;
    border: 2px solid lightgray;

    @media(max-width: 600px) {

        width: 80%;
    }

    &:focus {

        outline: none;
    }
`;

export const Icone = styled.span`

    font-size: 24px;
    color: #2b3180;
`;

export const InputCombo = styled.select`

    border-radius: 10px;
    font-size: 20px;
    padding: 10px;
    margin: 10px;
    width: 30%;
    border: 2px solid lightgray;

    @media(max-width: 600px) {

        width: 80%
    }

    &:focus {

        outline: none;
    }
`;

export const Options = styled.option`

    font-size: 24px;
    padding: 10px;
`;

export const TextExtra = styled.textarea`

    width: 100%;
    padding: 10px;
    border-radius: 10px;
    font-size: 20px;

    @media(max-width: 600px) {

        width: 80%;
        margin: 10px 0px;
    }

    &:focus {

        outline: none;
    }
`;

export const Submitbox = styled.div`

    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0px 30px;
    align-items: end;
    margin: 50px 0px;
`;

export const Value = styled.div`

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: auto;
    font-size: 30px;
    color: #36a9e1;
    padding: 0px 10px 20px 10px;
    font-weight: 900;

    @media (max-width: 600px) {

        margin: 20px 0px;
    }
`;


export const BoxSubmit = styled.div`

    padding: 5px;
    text-align: center;
`;

export const NavbarLink = styled(LinkS)`
    text-decoration: none;
    color: #2b3180;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    &:hover {
        color: #ff4834
    }
`;

export const BoxVinil = styled.div`

    display: ${({valid}) => (valid === '1' ? 'flex' : 'none')};
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 50px 0px;
    padding: 20px 0px;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
`;

export const VinilText = styled.p`

    font-size: 30px;
    letter-spacing: 2px;
    width: 60%;
    color: #2b3180;
    padding: 10px;
    text-align: start;
    font-weight: bold;
`;

export const VinilImg = styled.div`

    width: 150px;
    height: 150px;
    padding: 10px;
    border: 2px solid #2b3180;
    background-image: url(${({img}) => (img)});
    background-size: cover;
    border-radius: 100%;
    cursor: pointer;
`;


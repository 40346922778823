import styled from "styled-components";
import logo from '../../images/logo_slide.png'
import coment1 from '../../images/Comentario1.png'
import coment2 from '../../images/Comentario2.png'
import coment3 from '../../images/Comentario3.png'
import imgCentral from '../../images/img_F.jpeg'
import img1 from '../../images/img_L1.jpeg'
import img2 from '../../images/img_L2.jpeg'
import doll from '../../images/Ilustracao.svg'


export const CarroselContainer = styled.section`

    width: 100%;
    margin: auto;
    padding: 0px 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    margin: 100px 0px;

    position: relative;
    background-color: white;
`;

export const Slide = styled.section`

    width: 95%;
    height: 500px;
    margin: auto;
    padding: 10px;
    box-sizing: border-box;
    background-color: white;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 600px) {

        height: 900px;
    }
`;

export const SlideContent = styled.div`

    width: 60%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-right: 20px;

    @media(max-width: 600px) {

        width: 100%;
        height: 45%;
        margin-bottom: 50px;
        padding-right: 0px;
    }
`;

export const Logo = styled.div`

    width: 250px;
    height: 250px;
    box-sizing: border-box;
    background-image: url(${logo});
    background-size: cover;
`;

export const LogoDesc = styled.div`

    width: 100%;
    box-sizing: border-box;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    color: #2b3180;
    padding: 0px 10px;
`;

export const SlideSide = styled.div`

    width: 40%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    @media(max-width: 600px) {

        width: 100%;
        height: 50%;
    }
`;

export const ComentBox = styled.div`

    width: 100%;
    height: 30%;
    margin: 5px;
    box-sizing: border-box;
    background-image: url(${({Comentario}) => (Comentario === '1' ? coment1 : Comentario === '2' ? coment2 : coment3)});
    box-shadow: 0px 0px 5px grey;
    background-size: contain;
    background-repeat: no-repeat;

    @media(max-width: 600px) {

        height: 20%;
        width: 95%;
        margin: 0px;
        background-size: contain;
        background-position: center;
    }
`;

export const Service = styled.div`

    width: 45%;
    height: 40%;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    margin: 5px;
    box-shadow: 0px 0px 5px grey;

    @media(max-width: 600px) {

        width: 95%;
        height: unset;
        margin: 10px 5px;
    }
`;

export const IconeService = styled.div`

    margin: 10px;
    background-image: url(${logo});
    background-size: contain;
    background-repeat: no-repeat;
    width: 80px;
    height: 70px;

    @media(max-width: 600px) {

        width: 60px;
        height: 50px;
    }
`;

export const ServiceDesc = styled.div`

    font-size: 21px;
    padding: 20px 10px 10px 10px;
    font-weight: 700;
    width: 65%;
    color: #2b3180;

    @media(max-width: 600px) {

        font-size: 18px;
        padding: 20px 10px 20px 10px;
    }
`;

export const SideDoll = styled.div`

    width: 90%;
    height: 90%;
    align-self: center;
    background-image: url(${doll});
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 30px;

    @media (max-width: 600px) {

        height: 80%;
        margin-top: 200px;
    }
`;

export const SlideImg = styled.div`

    width: 95%;
    height: 99%;
    margin: 0px 10px;
    background-image: url(${imgCentral});
    background-size: cover;
    border: 3px solid white;
    box-shadow: 0px 0px 10px black;

    @media (max-width: 600px) {

        width: 100%;
        height: 90%;
    }
`;

export const SideImg = styled.div`

    width: 100%;
    height: 47%;
    margin: 3px 5px;
    background-image: url(${({Foto}) => (Foto === '1' ? img1 : img2)});
    background-size: cover;
    background-position: center;
    border: 3px solid white;
    box-shadow: 0px 0px 10px black;

    @media (max-width: 600px) {

        height: 44%;
    }
`;

import styled from "styled-components";
import back from '../../images/estampa7.jpg'
import logo from '../../images/logo_pis_white.png'

export const VinilContainer = styled.section`

    margin-top: 150px;
`;

export const VinilLogo = styled.div`

    height: 100px;
    width: 300px;
    background-image: linear-gradient(to top, rgba(43, 49, 128, 0.8), rgba(43, 49, 128, 0.8)), url(${back});
    border-radius: 0px 10px 0px 0px;
`;

export const LogoV = styled.div`

    width: 100%;
    height: 100%;
    background-image: url(${logo});
`;

export const VinilBox = styled.div`

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding: 50px 10px;
    background-image: linear-gradient(to top, rgba(43, 49, 128, 0.8), rgba(43, 49, 128, 0.8)), url(${back});

    @media(max-width: 600px) {

        padding: 10px 10px 50px 10px;
    }
`;

export const VinilText = styled.div`

    height: 400px;
    width: 470px;
    color: white;
    text-align: center;
    padding-top: 20px;
`;

export const VinilDescription = styled.p`

    font-size: 39px;
    letter-spacing: 0px;
    text-align: start;
    padding: 10px;
    font-weight: bold;
`;

export const VinilButton = styled.button`

    padding: 20px 40px;
    margin-top: 50px;
    background-color: white;
    color: #2b3180;
    font-size: 24px;
    letter-spacing: 1px;
    border: none;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;

    &:hover {

        background-color: #2b3180;
        border: 2px solid white;
        color: white;
    }

`;

export const VinilImageP = styled.div`

    height: 350px;
    width: 380px;
    background-image: url(${(props) => props.img});
    background-size: cover;
    background-position: center;
    border: 5px solid white;

    @media(max-width: 600px) {

        margin: 50px 0px;
    }
`;

export const VinilImageS = styled.div`

    height: 250px;
    width: 340px;
    background-image: url(${(props) => props.img});
    background-size: cover;
    border: 5px solid white;
`;

export const VinilContent = styled.div`

    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;    
    width: 100%;
    padding: 50px 0px;
`;

export const ContentCard = styled.div`

    width: 30%;
    border-radius: 0px 0px 0px 5px;
    border-left: 3px solid white;
    border-bottom: 3px solid white;

    @media(max-width: 600px) {

        width: 95%;
        margin: 30px 0px;
    }
`;

export const CardTitle = styled.div`

    display: flex;
    align-items: center;
`;

export const Title = styled.h3`

    font-size: 30px;
    color: white;
    letter-spacing: 2px;
    font-weight: bold;
    padding-bottom: 10px;
    text-shadow: 0px 0px 10px black;
`;

export const TitleIcon = styled.i`

    color: white;
    font-size: 35px;
    padding: 10px 10px;

    @media(max-width: 600px) {

        font-size: 50px;
    }
`;

export const CardDescription = styled.p`

    font-size: 22px;
    color: white;
    letter-spacing: 1px;
    text-align: start;
    padding: 15px;
    line-height: 30px;

    @media(max-width: 600px) {

        font-size: 24px;
    }
`;

export const Effectbox = styled.div`

    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 50px 50px;

    
    @media(max-width: 600px) {

        margin-top: 50px;
    }
`;

export const VinilEffect = styled.p`

    font-size: 35px;
    color: white;
    letter-spacing: 3px;
    font-weight: bold;
    text-align: center;
    font-style: italic;
`;


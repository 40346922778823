import React, { useEffect, useState } from 'react'
import {AiFillCheckCircle} from 'react-icons/ai'
import {TiDelete} from 'react-icons/ti'
import {
    CaractBox,
    LinhaBotao,
    LinhaBox,
    Nav, 
    NavButtom, 
    NavbarContainer, 
    NavbarLogo, 
    OrcBox, 
    Selecao, 
    Titulo,
    ButtonSubmit,
    InputFilter,
    DadosBox,
    TituloTable,
    ColumnTitle,
    LinhaTable,
    ColumnTable,
    ColumnIcone,
    Galeria,
    Foto,
    InputCombo,
    Options,
    TextExtra,
    Submitbox,
    Value,
    BoxSubmit,
    NavbarLink,
    BoxVinil,
    VinilText,
    VinilImg
} from './FilterNavbarElements'

import vinil1 from '../../images/estampa1.jpg'
import vinil2 from '../../images/estampa2.jpg'
import vinil3 from '../../images/estampa3.jpg'
import vinil4 from '../../images/estampa4.jpg'
import vinil5 from '../../images/estampa5.jpg'
import vinil6 from '../../images/estampa6.jpg'
import vinil7 from '../../images/estampa7.jpg'
import vinil8 from '../../images/estampa8.jpg'
import vinil9 from '../../images/estampa9.jpg'
import vinil10 from '../../images/estampa10.jpg'
import vinil11 from '../../images/estampa11.jpg'
import vinil12 from '../../images/estampa12.jpg'
import vinil13 from '../../images/estampa13.jpg'
import vinil14 from '../../images/estampa14.jpg'
import vinil15 from '../../images/estampa15.jpg'
import vinil16 from '../../images/estampa16.png'
import { useParams } from 'react-router-dom'

const NavFilter = () => {
    
    const params = useParams();
    var vinil = [vinil1, vinil2, vinil3, vinil4, vinil5, vinil6, vinil7, vinil8, vinil9, vinil10, vinil11, vinil12, vinil13, vinil14, vinil15, vinil16];
    var nomesVinil = ['Vidro', 'Master', 'Liso', 'Reflexo', 'Natural Stone', 'Cobalto', 'Stone Blue', 'Angra', 'Ilhabela Blue', 'Ilhabela Green', 'Maragogi', 'Maresias', 'Parati', 'Trancoso', 'Floripa', 'Jericoacoara']
    const [filtro, setFiltro] = useState(params.tipo === '1' ? ['9', '10', '11'] : ['0', '1', '2']);
    const [active, setActive] = useState(['1', '0', '0']);
    const [valor, setValor] = useState('R$ 00.000,00');
    const [pacote, setPacote] = useState('Soft');
    const [dimensoes, setDimensoes] = useState('');
    const [view, SetView] = useState(0);
    const [dados, setDados] = useState({
        nome: '',
        dimensoes: '',
        pacote: '',
        text: ''
    });

    function gotoHome() {

        window.location.href = '/'
    }

    const HandleChange = (event) => {

        var id = event.target.id;
        var value = event.target.value;

        if (id === 'nome') {

            setDados(prevState => {

                return {...prevState, nome: value}
            })

        } else if (id === 'dimensoes') {

            setDados(prevState => {

                return {...prevState, dimensoes: value}

            })

            if(value === '4x2') {

                if (params.tipo === '0') {

                    if (pacote === 'Soft') {
    
                        setValor('R$ 29.000,00');
        
                    } else if (pacote === 'Premium') {
        
                        setValor('R$ 32.200,00');
        
                    } else if (pacote === 'Master') {
        
                        setValor('R$ 51.500,00');
                    }

                } else if (params.tipo === '1'){

                    if (pacote === 'Soft') {
    
                        setValor('R$ 23.500,00');
        
                    } else if (pacote === 'Premium') {
        
                        setValor('R$ 26.800,00');
        
                    } else if (pacote === 'Master') {
        
                        setValor('R$ 43.500,00');
                    }
                }

            } else if(value === '5x2') {

                if (params.tipo === '0') {

                    if (pacote === 'Soft') {
    
                        setValor('R$ 33.600,00');
        
                    } else if (pacote === 'Premium') {
        
                        setValor('R$ 34.100,00');
        
                    } else if (pacote === 'Master') {
        
                        setValor('R$ 56.400,00');
                    }

                } else if (params.tipo === '1') {

                    if (pacote === 'Soft') {
    
                        setValor('R$ 25.800,00');
        
                    } else if (pacote === 'Premium') {
        
                        setValor('R$ 29.500,00');
        
                    } else if (pacote === 'Master') {
        
                        setValor('R$ 45.800,00');
                    }
                }
    
            } else if(value === '6x3') {

                if (params.tipo === '0') {

                    if (pacote === 'Soft') {
    
                        setValor('R$ 41.750,00');
        
                    } else if (pacote === 'Premium') {
        
                        setValor('R$ 43.500,00');
        
                    } else if (pacote === 'Master') {
        
                        setValor('R$ 64.000,00');
                    }

                } else if (params.tipo === '1') {

                    if (pacote === 'Soft') {
    
                        setValor('R$ 32.500,00');
        
                    } else if (pacote === 'Premium') {
        
                        setValor('R$ 33.500,00');
        
                    } else if (pacote === 'Master') {
        
                        setValor('R$ 50.500,00');
                    }
                }
            
            } else if(value === '8x3') {

                if (params.tipo === '0') {

                    if (pacote === 'Soft') {
    
                        setValor('R$ 53.200,00');
        
                    } else if (pacote === 'Premium') {
        
                        setValor('R$ 55.900,00');
        
                    } else if (pacote === 'Master') {
        
                        setValor('R$ 79.500,00');
                    }

                } else if (params.tipo === '1') {

                    if (pacote === 'Soft') {
    
                        setValor('R$ 37.800,00');
        
                    } else if (pacote === 'Premium') {
        
                        setValor('R$ 39.500,00');
        
                    } else if (pacote === 'Master') {
        
                        setValor('R$ 58.200,00');
                    }
                }
               
            }

            setDimensoes(value);

        } else if (id === 'pacote') {

            setDados(prevState => {

            return {...prevState, pacote: value}

            })

            if(value === 'Soft') {

                if (params.tipo === '0') {

                    if (dimensoes === '4x2') {
    
                        setValor('R$ 29.000,00');
        
                    } else if (dimensoes === '5x2') {
        
                        setValor('R$ 33.600,00');
        
                    } else if (dimensoes === '6x3') {
        
                        setValor('R$ 41.750,00');
    
                    } else if (dimensoes === '8x3') {
        
                        setValor('R$ 53.200,00');
                    }

                } else if (params.tipo === '1') {

                    if (dimensoes === '4x2') {
    
                        setValor('R$ 23.500,00');
        
                    } else if (dimensoes === '5x2') {
        
                        setValor('R$ 25.800,00');
        
                    } else if (dimensoes === '6x3') {
        
                        setValor('R$ 32.500,00');
    
                    } else if (dimensoes === '8x3') {
        
                        setValor('R$ 37.800,00');
                    }
                }
    
            } else if(value === 'Premium') {

                if (params.tipo === '0') {

                    if (dimensoes === '4x2') {
    
                        setValor('R$ 32.200,00');
        
                    } else if (dimensoes === '5x2') {
        
                        setValor('R$ 34.100,00');
        
                    } else if (dimensoes === '6x3') {
        
                        setValor('R$ 43.500,00');
                    
                    } else if (dimensoes === '8x3') {
        
                        setValor('R$ 55.900,00');
                    }

                } else if (params.tipo === '1') {

                    if (dimensoes === '4x2') {
    
                        setValor('R$ 26.800,00');
        
                    } else if (dimensoes === '5x2') {
        
                        setValor('R$ 29.500,00');
        
                    } else if (dimensoes === '6x3') {
        
                        setValor('R$ 33.500,00');
                    
                    } else if (dimensoes === '8x3') {
        
                        setValor('R$ 39.500,00');
                    }
                }

    
            } else if(value === 'Master') {

                if (params.tipo === '0') {

                    if (dimensoes === '4x2') {
    
                        setValor('R$ 51.500,00');
        
                    } else if (dimensoes === '5x2') {
        
                        setValor('R$ 56.400,00');
        
                    } else if (dimensoes === '6x3') {
        
                        setValor('R$ 64.000,00');
                    
                    } else if (dimensoes === '8x3') {
        
                        setValor('R$ 79.500,00');
                    }
                    
                } else if (params.tipo === '1') {

                    if (dimensoes === '4x2') {
    
                        setValor('R$ 43.500,00');
        
                    } else if (dimensoes === '5x2') {
        
                        setValor('R$ 45.800,00');
        
                    } else if (dimensoes === '6x3') {
        
                        setValor('R$ 50.500,00');
                    
                    } else if (dimensoes === '8x3') {
        
                        setValor('R$ 58.200,00');
                    }
                }

            } 

            setPacote(value);

        } else {

            setDados(prevState => {

                return {...prevState, text: value}
            })

        }
    }

    const selectFilter = (event => {

        if (event.target.id === 'soft') {

            if (params.tipo === '1') {

                setFiltro(['9', '10', '11']);

            } else if (params.tipo === '0'){

                setFiltro(['0', '1', '2']);
            }
            
            setActive(['1', '0', '0']);
        };

        if (event.target.id === 'premium') {

            if (params.tipo === '1') {

                setFiltro(['12', '13', '14']);

            } else if (params.tipo === '0'){

                setFiltro(['3', '4', '5']);
            }

            setActive(['0', '1', '0']);
        };

        if (event.target.id === 'master') {

            if (params.tipo === '1') {

                setFiltro(['15', '16', '17']);

            } else if (params.tipo === '0'){

                setFiltro(['6', '7', '8']);
            }

            setActive(['0', '0', '1']);
        };
    })

    function handleClick() {

        if (dados['nome'] === '' || dados['dimensoes'] === '' || dados['pacote'] === '') {

            alert('Preenha todos os campos');

        } else {

            if (dados['text'] !== '') {

                if (params.tipo === '0') {

                    window.location.href = `#/obrigado/${dados['nome']}/${dados['dimensoes']}/${dados['pacote']}/${dados['text']}/vinil`;
                
                } else {

                    window.location.href = `#/obrigado/${dados['nome']}/${dados['dimensoes']}/${dados['pacote']}/${dados['text']}/${nomesVinil[params.vinil]}`;
                }
   

            } else {

                if (params.tipo === '0') {

                    window.location.href = `#/obrigado/${dados['nome']}/${dados['dimensoes']}/${dados['pacote']}/desc/vinil`;

                } else {

                    window.location.href = `#/obrigado/${dados['nome']}/${dados['dimensoes']}/${dados['pacote']}/desc/${nomesVinil[params.vinil]}`;
                }

                
            }
        }
    }

    useEffect(() => {

        window.scrollTo(0,0);

        if (params.tipo === '1') {

            SetView(1);
        }
        
    }, []);

    return (
    <>
        <Nav>
            <NavbarContainer>
                <NavbarLink onClick={gotoHome} spy={true} offset={-100} smooth={true} duration={500} to="topo"><NavbarLogo /></NavbarLink>
                <NavButtom onClick={gotoHome}>Home</NavButtom>
            </NavbarContainer>
        </Nav>
        <Titulo id='topo'>Orçamento</Titulo>
        <OrcBox>
            <Selecao>
                <LinhaBotao id='soft' onClick={selectFilter} Active={active[0]}>Soft</LinhaBotao>
                <LinhaBotao id='premium' onClick={selectFilter} Active={active[1]}>Premium</LinhaBotao>
                <LinhaBotao id='master' onClick={selectFilter} Active={active[2]}>Master</LinhaBotao>
            </Selecao>
            <Galeria>
                <Foto isProp={filtro[0]}/>
                <Foto isProp={filtro[1]}/>
                <Foto isProp={filtro[2]}/>
            </Galeria>
            <LinhaBox show={view}>
                <CaractBox>
                    <TituloTable>
                        <ColumnTitle>Soft</ColumnTitle>
                        <ColumnTitle>Premium</ColumnTitle>
                        <ColumnTitle>Master</ColumnTitle>
                    </TituloTable>
                    <LinhaTable>
                        <ColumnTable>Revestimento / estampa azul cristal</ColumnTable>
                        <ColumnIcone ativo={active[0]}><AiFillCheckCircle/></ColumnIcone>
                        <ColumnIcone ativo={active[1]}><AiFillCheckCircle/></ColumnIcone>
                        <ColumnIcone ativo={active[2]}><AiFillCheckCircle/></ColumnIcone>
                    </LinhaTable>
                    <LinhaTable>
                        <ColumnTable>Borda em pedra cariri boleada nas quinas interna e externa</ColumnTable>
                        <ColumnIcone ativo={active[0]}><AiFillCheckCircle/></ColumnIcone>
                        <ColumnIcone ativo={active[1]}><AiFillCheckCircle/></ColumnIcone>
                        <ColumnIcone ativo={active[2]}><AiFillCheckCircle/></ColumnIcone>
                    </LinhaTable>
                    <LinhaTable>
                        <ColumnTable>2 ralos de fundo</ColumnTable>
                        <ColumnIcone ativo={active[0]}><AiFillCheckCircle/></ColumnIcone>
                        <ColumnIcone ativo={active[1]}><AiFillCheckCircle/></ColumnIcone>
                        <ColumnIcone ativo={active[2]}><AiFillCheckCircle/></ColumnIcone>
                    </LinhaTable>
                    <LinhaTable>
                        <ColumnTable>2 retornos</ColumnTable>
                        <ColumnIcone ativo={active[0]}><AiFillCheckCircle/></ColumnIcone>
                        <ColumnIcone ativo={active[1]}><AiFillCheckCircle/></ColumnIcone>
                        <ColumnIcone ativo={active[2]}><AiFillCheckCircle/></ColumnIcone>
                    </LinhaTable>
                    <LinhaTable>
                        <ColumnTable>1 aspiração e 1 nivelador de borda</ColumnTable>
                        <ColumnIcone ativo={active[0]}><AiFillCheckCircle/></ColumnIcone>
                        <ColumnIcone ativo={active[1]}><AiFillCheckCircle/></ColumnIcone>
                        <ColumnIcone ativo={active[2]}><AiFillCheckCircle/></ColumnIcone>
                    </LinhaTable>
                    <LinhaTable>
                        <ColumnTable>Iluminação em led rgb</ColumnTable>
                        <ColumnIcone ativo={active[0]}><AiFillCheckCircle/></ColumnIcone>
                        <ColumnIcone ativo={active[1]}><AiFillCheckCircle/></ColumnIcone>
                        <ColumnIcone ativo={active[2]}><AiFillCheckCircle/></ColumnIcone>
                    </LinhaTable>
                    <LinhaTable>
                        <ColumnTable>Tampa de aço galvanizado</ColumnTable>
                        <ColumnIcone ativo={active[0]}><AiFillCheckCircle/></ColumnIcone>
                        <ColumnIcone ativo={active[1]}><AiFillCheckCircle/></ColumnIcone>
                        <ColumnIcone ativo={active[2]}><AiFillCheckCircle/></ColumnIcone>
                    </LinhaTable>
                    <LinhaTable>
                        <ColumnTable>Casa de máquinas</ColumnTable>
                        <ColumnIcone ativo={active[0]}><AiFillCheckCircle/></ColumnIcone>
                        <ColumnIcone ativo={active[1]}><AiFillCheckCircle/></ColumnIcone>
                        <ColumnIcone ativo={active[2]}><AiFillCheckCircle/></ColumnIcone>
                    </LinhaTable>
                    <LinhaTable>
                        <ColumnTable>1 conjunto filtrante</ColumnTable>
                        <ColumnIcone ativo={active[0]}><AiFillCheckCircle/></ColumnIcone>
                        <ColumnIcone ativo={active[1]}><AiFillCheckCircle/></ColumnIcone>
                        <ColumnIcone ativo={active[2]}><AiFillCheckCircle/></ColumnIcone>
                    </LinhaTable>
                    <LinhaTable>
                        <ColumnTable>Quadro de comando simples</ColumnTable>
                        <ColumnIcone ativo={active[0]}><AiFillCheckCircle/></ColumnIcone>
                        <ColumnIcone ativo={active[1]}><AiFillCheckCircle/></ColumnIcone>
                        <ColumnIcone ativo={active[2]}><AiFillCheckCircle/></ColumnIcone>
                    </LinhaTable>
                    <LinhaTable>
                        <ColumnTable>Borda em pedra atérmica</ColumnTable>
                        <ColumnIcone ativo={active[0]} Set='not'><TiDelete/></ColumnIcone>
                        <ColumnIcone ativo={active[1]}><AiFillCheckCircle/></ColumnIcone>
                        <ColumnIcone ativo={active[2]}><AiFillCheckCircle/></ColumnIcone>
                    </LinhaTable>
                    <LinhaTable>
                        <ColumnTable>Quadro de comando simples com temporizador</ColumnTable>
                        <ColumnIcone ativo={active[0]} Set='not'><TiDelete/></ColumnIcone>
                        <ColumnIcone ativo={active[1]}><AiFillCheckCircle/></ColumnIcone>
                        <ColumnIcone ativo={active[2]}><AiFillCheckCircle/></ColumnIcone>
                    </LinhaTable>
                    <LinhaTable>
                        <ColumnTable>Cascata estilo naja</ColumnTable>
                        <ColumnIcone ativo={active[0]} Set='not'><TiDelete/></ColumnIcone>
                        <ColumnIcone ativo={active[1]}><AiFillCheckCircle/></ColumnIcone>
                        <ColumnIcone ativo={active[2]}><AiFillCheckCircle/></ColumnIcone>
                    </LinhaTable>
                    <LinhaTable>
                        <ColumnTable>Banco com 2 pontos de hidromassagem</ColumnTable>
                        <ColumnIcone ativo={active[0]} Set='not'><TiDelete/></ColumnIcone>
                        <ColumnIcone ativo={active[1]}><AiFillCheckCircle/></ColumnIcone>
                        <ColumnIcone ativo={active[2]}><AiFillCheckCircle/></ColumnIcone>
                    </LinhaTable>
                    <LinhaTable>
                        <ColumnTable>Revestimento / estampa tasos da marca Elizabeth</ColumnTable>
                        <ColumnIcone ativo={active[0]} Set='not'><TiDelete/></ColumnIcone>
                        <ColumnIcone ativo={active[1]}><AiFillCheckCircle/></ColumnIcone>
                        <ColumnIcone ativo={active[2]}><AiFillCheckCircle/></ColumnIcone>
                    </LinhaTable>
                    <LinhaTable>
                        <ColumnTable>Revestimento / estampa11 javas lago</ColumnTable>
                        <ColumnIcone ativo={active[0]} Set='not'><TiDelete/></ColumnIcone>
                        <ColumnIcone ativo={active[1]} Set='not'><TiDelete/></ColumnIcone>
                        <ColumnIcone ativo={active[2]}><AiFillCheckCircle/></ColumnIcone>
                    </LinhaTable>
                    <LinhaTable>
                        <ColumnTable>4 ralos de fundo</ColumnTable>
                        <ColumnIcone ativo={active[0]} Set='not'><TiDelete/></ColumnIcone>
                        <ColumnIcone ativo={active[1]} Set='not'><TiDelete/></ColumnIcone>
                        <ColumnIcone ativo={active[2]}><AiFillCheckCircle/></ColumnIcone>
                    </LinhaTable>
                    <LinhaTable>
                        <ColumnTable>4 retornos</ColumnTable>
                        <ColumnIcone ativo={active[0]} Set='not'><TiDelete/></ColumnIcone>
                        <ColumnIcone ativo={active[1]} Set='not'><TiDelete/></ColumnIcone>
                        <ColumnIcone ativo={active[2]}><AiFillCheckCircle/></ColumnIcone>
                    </LinhaTable>
                    <LinhaTable>
                        <ColumnTable>2 aspirações e 2 niveladores de borda</ColumnTable>
                        <ColumnIcone ativo={active[0]} Set='not'><TiDelete/></ColumnIcone>
                        <ColumnIcone ativo={active[1]} Set='not'><TiDelete/></ColumnIcone>
                        <ColumnIcone ativo={active[2]}><AiFillCheckCircle/></ColumnIcone>
                    </LinhaTable>
                    <LinhaTable>
                        <ColumnTable>Tampa em alumínio fundido com grelha</ColumnTable>
                        <ColumnIcone ativo={active[0]} Set='not'><TiDelete/></ColumnIcone>
                        <ColumnIcone ativo={active[1]} Set='not'><TiDelete/></ColumnIcone>
                        <ColumnIcone ativo={active[2]}><AiFillCheckCircle/></ColumnIcone>
                    </LinhaTable>
                    
                    <LinhaTable>
                        <ColumnTable>Quadro de comando automatizado</ColumnTable>
                        <ColumnIcone ativo={active[0]} Set='not'><TiDelete/></ColumnIcone>
                        <ColumnIcone ativo={active[1]} Set='not'><TiDelete/></ColumnIcone>
                        <ColumnIcone ativo={active[2]}><AiFillCheckCircle/></ColumnIcone>
                    </LinhaTable>
                    <LinhaTable>
                        <ColumnTable>Cascata de sobrepor ou embutida</ColumnTable>
                        <ColumnIcone ativo={active[0]} Set='not'><TiDelete/></ColumnIcone>
                        <ColumnIcone ativo={active[1]} Set='not'><TiDelete/></ColumnIcone>
                        <ColumnIcone ativo={active[2]} ><AiFillCheckCircle/></ColumnIcone>
                    </LinhaTable>
                    <LinhaTable>
                        <ColumnTable>Hidromassagem com 6 pontos de hidro, com estrutura integrada ou separada 1,50x1,50x1m</ColumnTable>
                        <ColumnIcone ativo={active[0]} Set='not'><TiDelete/></ColumnIcone>
                        <ColumnIcone ativo={active[1]} Set='not'><TiDelete/></ColumnIcone>
                        <ColumnIcone ativo={active[2]}><AiFillCheckCircle/></ColumnIcone>
                    </LinhaTable>
                    <LinhaTable>
                        <ColumnTable>Sistema de automação com alexa</ColumnTable>
                        <ColumnIcone ativo={active[0]} Set='not'><TiDelete/></ColumnIcone>
                        <ColumnIcone ativo={active[1]} Set='not'><TiDelete/></ColumnIcone>
                        <ColumnIcone ativo={active[2]}><AiFillCheckCircle/></ColumnIcone>
                    </LinhaTable>
                    <LinhaTable>
                        <ColumnTable>Aquecedor elétrico</ColumnTable>
                        <ColumnIcone ativo={active[0]} Set='not'><TiDelete/></ColumnIcone>
                        <ColumnIcone ativo={active[1]} Set='not'><TiDelete/></ColumnIcone>
                        <ColumnIcone ativo={active[2]}><AiFillCheckCircle/></ColumnIcone>
                    </LinhaTable>
                </CaractBox>
            </LinhaBox>
        </OrcBox>
        <BoxVinil valid={params.tipo}>
            <VinilText>Vinil Selecionado: {nomesVinil[params.vinil]}</VinilText>
            <VinilImg img={vinil[params.vinil]}/>
        </BoxVinil>
        <Submitbox>
            <DadosBox>
                <InputFilter id='nome' onChange={HandleChange} placeholder="Nome"/>
                <InputCombo id='dimensoes' onChange={HandleChange}>
                    <Options disabled selected>Dimensões</Options>
                    <Options value='4x2'>4x2</Options>
                    <Options value='5x2'>5x2</Options>
                    <Options value='6x3'>6x3</Options>
                    <Options value='8x3'>8x3</Options>
                </InputCombo>
                <InputCombo id='pacote' onChange={HandleChange}>
                    <Options disabled selected>Pacote</Options>
                    <Options value='Soft'>Soft</Options>
                    <Options value='Premium'>Premium</Options>
                    <Options value='Master'>Master</Options>
                </InputCombo>
                <TextExtra id='text' onChange={HandleChange} placeholder='Comentário (opcional)'/>
            </DadosBox>
            <BoxSubmit>
                <Value>{valor}</Value>
                <ButtonSubmit onClick={handleClick}>Orçamento via Whatsapp</ButtonSubmit>
            </BoxSubmit>
        </Submitbox>
    </>
  )
}

export default NavFilter



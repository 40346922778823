import styled from "styled-components";
import home from '../../../images/home_port.jpg'
import bloco from '../../../images/bloco.jpg'
import constru from '../../../images/constru.jpg'
import chapisco from '../../../images/chapisco.webp'
import hidro from '../../../images/hidro.jpg'
import ralo from '../../../images/ralo.jpeg'
import retorno from '../../../images/retorno.jpg'
import quadro from '../../../images/quadro.webp'
import quadro_pis from '../../../images/quadro_pis.jpg'
import tubulacao from '../../../images/tubulação.jpg'

export const SectionBox = styled.section`

    width: 100%;
    margin-top: 200px;

    @media(max-width: 600px) {

        margin-top: 0px;
    }
`;

export const SectionContainer= styled.div`

    width: 100%;
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 75px;

    @media(max-width: 600px) {

        margin-bottom: 20px;
    }
`;

export const SectionImage= styled.div`

    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media(max-width: 600px) {

        width: 100%;
    }
`;

export const ImageSection= styled.div`

    width: 90%;
    height: 400px;
    background-image: ${({ $main }) => ($main === 1 ? `url(${bloco})` : $main === 2 ? `url(${hidro})` : $main === 3 ? `url(${quadro})` : `url(${home})`)};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 0px 80px 0px 80px;
    box-shadow: 0px 0px 10px gray;
`;

export const SectionText= styled.div`

    width: 50%;
    padding: 10px;

    @media(max-width: 600px) {

        width: 100%;
        text-align: center;
        padding: 20px 10px 100px 10px;
    }
`;

export const SectionTitle= styled.h2`

    width: 100%;
    font-size: 50px;
    padding: 30px 0px;
    color: #2b3180;
`;

export const SectionContent= styled.p`

    width: 100%;
    font-size: 30px;

    @media(max-width: 600px) {

        padding: 10px;
    }
`;

export const SideText= styled.div`

    width: 80%;
    margin: auto;
    padding: 30px 20px;
    border-radius: 10px;
    background-color: rgba(43, 49, 128, 0.7);

    @media(max-width: 600px) {

        width: 90%;
    }
`;

export const ContentSide= styled.p`

    width: 100%;
    text-align: end;
    font-size: 26px;
    color: white;
`;

export const SideImage= styled.div`

    width: 100%;
`;

export const ImageSide= styled.div`

    width: 80%;
    height: 150px;
    margin: 20px 0px;
    background-image:${({ $side }) => ($side === 1 ? `url(${constru})` : $side === 2 ? `url(${chapisco})` : $side === 3 ? `url(${ralo})`: $side === 4 ? `url(${retorno})` : $side === 5 ? `url(${quadro_pis})` : $side === 6 ? `url(${tubulacao})` : 'unset')};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 30px;
    box-shadow: 0px 0px 10px gray;

    @media(max-width: 600px) {

        width: 90%;
        margin: 40px auto;
    }
`;
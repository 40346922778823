import React, { useRef, useState } from 'react'
import { BsArrowRightCircleFill, BsArrowLeftCircleFill } from 'react-icons/bs'
import {
    ReformasContainer,
    ReformasLogo,
    ReformasBox,
    LogoRef,
    ReformasFotos,
    Foto,
    ReformasTopicos,
    LinhaTopico,
    IconeTopico,
    Topico,
    Buttons,
    CarouselRef,
    ButtonArrow,
    RefButton,
    RefGaleria,
    ImgGaleria,
    SubFoto,
    RefNome
} from './ReformasElements'

const Reformas = () => {

    const carousel = useRef(null);
    const posiImg = ['1', '2', '3', '4', '5', '6', '7'];
    const [contador, setCont] = useState(0);

    const handleLeftClick = (e) => {

        e.preventDefault()

        if (window.innerWidth > 600) {

            carousel.current.scrollLeft -= carousel.current.offsetWidth / 4;

        } else {

            carousel.current.scrollLeft -= carousel.current.offsetWidth;
        }
        

        if (contador > 0) {

            setCont(value => value - 1); 
        }
    }

    const handleRightClick = (e) => {

        e.preventDefault()

        if (window.innerWidth > 600) {

            carousel.current.scrollLeft += carousel.current.offsetWidth / 4;

        } else {

            carousel.current.scrollLeft += carousel.current.offsetWidth;
        }
        
        if (contador < 6) {

            setCont(value => value + 1);
        }
    }

    function handleClick() {

        var value_nome = document.getElementById('nome').value;

        if (value_nome === '') {

            alert('Coloque seu nome!');

        } else {

            var link = `https://wa.me/5585991770817/?text=Ol%c3%a1+me+chamo+${value_nome}%2c+preciso+de+um+or%c3%a7amento+para+um+servi%c3%a7o%c2%a0de%c2%a0reforma.`;
            window.open(link, '_blank');
        }
    }

  return (
    <ReformasContainer id='ref'>
        <ReformasLogo>
            <LogoRef />
        </ReformasLogo>
        <ReformasBox>
            <CarouselRef>
                <ReformasFotos ref={carousel}>
                    <Foto position={posiImg[contador]} Foto='1'><SubFoto>Pisos e Revestimentos</SubFoto></Foto>
                    <Foto position={posiImg[contador]} Foto='2'><SubFoto>Alvenarias</SubFoto></Foto>
                    <Foto position={posiImg[contador]} Foto='3'><SubFoto>Elétrica</SubFoto></Foto>
                    <Foto position={posiImg[contador]} Foto='4'><SubFoto>Gerenciamento de Obras</SubFoto></Foto>
                    <Foto position={posiImg[contador]} Foto='5'><SubFoto>Gesso e Drywall</SubFoto></Foto>
                    <Foto position={posiImg[contador]} Foto='6'><SubFoto>Pintura</SubFoto></Foto>
                    <Foto position={posiImg[contador]} Foto='7'><SubFoto>Projetos e Designer de Interiores</SubFoto></Foto>
                </ReformasFotos>
                <Buttons>
                    <ButtonArrow onClick={handleLeftClick} ><BsArrowLeftCircleFill/></ButtonArrow>
                    <ButtonArrow onClick={handleRightClick}><BsArrowRightCircleFill/></ButtonArrow>
                </Buttons>
            </CarouselRef>
            <ReformasTopicos>
                <LinhaTopico>
                    <IconeTopico />
                    <Topico>Reformas</Topico>
                </LinhaTopico>
                <LinhaTopico>
                    <IconeTopico />
                    <Topico>Ajustes</Topico>
                </LinhaTopico>
                <LinhaTopico>
                    <IconeTopico />
                    <Topico>Obras</Topico>
                </LinhaTopico>
                <LinhaTopico>
                    <IconeTopico />
                    <Topico>Construções</Topico>
                </LinhaTopico>
                <RefNome id='nome' placeholder='Nome'/>
                <RefButton onClick={handleClick}>Faça seu orçamento</RefButton>
            </ReformasTopicos>
            <RefGaleria>
                <ImgGaleria Foto='1'/>
                <ImgGaleria Foto='2'/>
                <ImgGaleria Foto='3'/>
                <ImgGaleria Foto='4'/>
                <ImgGaleria Foto='5'/>
                <ImgGaleria Foto='6'/>
                <ImgGaleria Foto='7'/>
            </RefGaleria>
        </ReformasBox>
    </ReformasContainer>
  )
}

export default Reformas
import styled from "styled-components";
import {Link as LinkS} from 'react-scroll'

export const SimuladorContainer = styled.div`

    width: 100%;
    max-width: 1300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 50px 0px;
    margin: auto;
    padding-bottom: 50px;
`;

export const SimuladorBox = styled.div`

    width: 60%;

    @media(max-width: 600px) {

        width: 100%;
    }
`;

export const SubBox = styled.div`

    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 30px 0px;
    border-bottom: 5px solid #2b3180;
;
`;

export const VinilImg = styled.div`

    width: 140px;
    height: 140px;
    margin: auto;
    border-radius: 100%;
    background-image: url(${({img}) => (img != '' ? img : '')});
    background-size: contain;
    border: 1px solid #2b3180;
`;

export const VinilDescription = styled.p`

    font-size: 24px;
    font-weight: bold;
    color: #2b3180;
    padding-top: 20px;
    text-align: center;
    letter-spacing: 2px;
`;

export const VinilBox = styled(LinkS)`

    width: 20%;
    transition: 0.1s;
    cursor: pointer;

    &:hover, &:active {
        
        transform: scale(1.1);

        ${VinilDescription} {

            color: red;
        }

        ${VinilImg} {

            border: 2px solid red;
        }
    }

    @media(max-width: 600px) {

        width: 50%;
        margin-bottom: 40px;
    }
`;

export const ViewBox = styled.div`

    width: 35%;

    @media(max-width: 600px) {

        width: 100%;
        margin-top: 50px;
        text-align: center;
    }
`;

export const PisImg = styled.div`

    width: 100%;
    height: 250px;
    margin-top: 50px;
    background-image: url(${({img}) => (img != '' ? img : '')});
    background-size: cover;
    border: 2px solid #2b3180;
    margin: auto;

    @media(max-width: 600px) {

        width: 90%;
        margin-top: 50px;
    }
`;

export const SimulatorDescription = styled.p`

    width: 100%;
    font-size: 18px;
    letter-spacing: 2px;
    color: #2b3180;
    margin: 20px 0px;

    @media(max-width: 600px) {

        width: 100%;
        text-align: center;
    }
`;

export const SimulatorButton = styled.button`

    width: 100%;
    border-radius: 5px;
    font-weight: bold;
    border: 2px solid #2b3180;
    background-color: #2b3180;
    color: white;
    font-size: 24px;
    padding: 10px 20px;
    cursor: pointer;

    &:hover {

        background-color: white;
        color: #2b3180;
    }

    @media(max-width: 600px) {

        width: 90%;
        margin: 30px 0px;
    }
`;
import '../App.css';
import { useState } from 'react';
import Capa from '../components/Capa';
import Navbar from '../components/Navbar';
import Sobre from '../components/Sobre';
import Footer from '../components/Footer';
import Piscinas from '../components/Piscinas';
import Reformas from '../components/Reformas';
import Contato from '../components/Contato';
import Sidebar from '../components/Sidebar';
import Carrosel from '../components/Carrosel';
import Vinil from '../components/Vinil';

function App() {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = ()=> {

    setIsOpen(!isOpen);

    if (!isOpen) {

      document.body.style.overflow = 'hidden';

    } else {

      document.body.style.overflow = 'auto';
    }
    
  }

  return (
    <>
      <Sidebar toggle={toggle} isOpen={isOpen}/>
      <Navbar toggle={toggle}/>
      <Capa />
      <Sobre />
      <Vinil />
      <Piscinas />
      <Carrosel />
      <Reformas />
      <Contato />
      <Footer />
    </>
  );
}

export default App;

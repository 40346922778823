import React, { useEffect, useState } from 'react'
import { 
    CardDescription,
    CardTitle,
    ContentCard,
    Effectbox,
    LogoV,
    Title,
    TitleIcon,
    VinilBox, 
    VinilButton, 
    VinilContainer, 
    VinilContent, 
    VinilDescription, 
    VinilEffect, 
    VinilImageP, 
    VinilImageS, 
    VinilLogo, 
    VinilText
} from './VinilElements'

import { MdOutlineWaterDrop } from 'react-icons/md'

import p1 from '../../images/pisvinil1.jpg'
import p2 from '../../images/pisvinil2.jpg'
import p3 from '../../images/pisvinil3.jpg'
import s1 from '../../images/pisvinil4.jpg'
import s2 from '../../images/pisvinil5.jpg'
import s3 from '../../images/pisvinil6.jpg'

const Vinil = () => {

    var imagesP = [p1, p2, p3];
    var imagesS = [s1, s2, s3];
    const [cont, setCont] = useState(0);
    var timer;

    useEffect(() => {

    timer = setInterval(() => {

            if (cont === 0) {
    
                setCont(1);
    
            } else if (cont === 1){
    
                setCont(2);
                
            } else if (cont === 2){
    
                setCont(0);
            }
    
        }, 3000);

        return () => clearInterval(timer);
    });

    const gotoSimulador = (event) => {

        window.location.href = '#/simulador';
    }


  return (
    <VinilContainer id='vinil'>
        <VinilLogo>
            <LogoV />
        </VinilLogo>
        <VinilBox>
            <VinilText>
                <VinilDescription>Inovação em qualidade e tecnologia nas piscinas de Vinil no Ceará!</VinilDescription>
                <VinilButton onClick={gotoSimulador}>ORÇAMENTO</VinilButton>
            </VinilText>
            <VinilImageP img={imagesP[cont]}></VinilImageP>
            <VinilImageS img={imagesS[cont]}></VinilImageS>
            <Effectbox>
                <VinilEffect>Até 30% mais barato</VinilEffect>
            </Effectbox>
            <VinilContent>
                <ContentCard>
                    <CardTitle>
                        <TitleIcon><MdOutlineWaterDrop/></TitleIcon>
                        <Title>O que é piscina de vinil?</Title>
                    </CardTitle>
                    <CardDescription>
                        As piscinas de vinil possuem uma estrutura de concreto armado ou alvenaria revestida com materiais chamados de bolsão de vinil. Feito com um material plástico flexível e resistente.
                    </CardDescription>
                </ContentCard>
                <ContentCard>
                    <CardTitle>
                        <TitleIcon><MdOutlineWaterDrop/></TitleIcon>
                        <Title>Principais Caracteríticas</Title>
                    </CardTitle>
                    <CardDescription>
                        As características notáveis da piscina de vinil incluem a falta de argamassa, permitindo limpar piscina sem o acúmulo de sujeira, bem como a impermeabilização que impede a infiltração de longo prazo e elimina custos.
                    </CardDescription>
                </ContentCard>
                <ContentCard>
                    <CardTitle>
                        <TitleIcon><MdOutlineWaterDrop/></TitleIcon>
                        <Title>Custo - Benefício</Title>
                    </CardTitle>
                    <CardDescription>
                    A piscina de vinil é uma excelente opção para quem busca um ótimo custo/benefício. Comparada a outros tipos de piscinas, como as de alvenaria ou fibra de vidro, a piscina de vinil geralmente apresenta um custo inicial mais acessível. 
                    </CardDescription>
                </ContentCard>
            </VinilContent>
        </VinilBox>
    </VinilContainer>
  )
}

export default Vinil